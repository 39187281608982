<template>
  <el-table
    :data="tableData"
    @selection-change="handleSelectionChange"
    max-height="400"
  >
    <!-- <el-table-column type="selection" width="55px"></el-table-column> -->
    <el-table-column prop="marketId" label="市场ID"></el-table-column>
    <el-table-column prop="marketName" label="市场名称"></el-table-column>
    <el-table-column prop="versionCode" label="市场版本"></el-table-column>
    <el-table-column>
      <template slot="header" slot-scope="scope">
        <el-checkbox
          v-model="selectAll1"
          @change="handleSelectAll('checkbox1')"
        ></el-checkbox>
        同步国际化
      </template>
      <template slot-scope="scope">
        <el-checkbox v-model="scope.row.checkbox1"></el-checkbox>
      </template>
    </el-table-column>
    <el-table-column v-if="isSetConfig">
      <template slot="header" slot-scope="scope">
        <el-checkbox
          v-model="selectAll2"
          @change="handleSelectAll('checkbox2')"
        ></el-checkbox>
        同步配置
      </template>
      <template slot-scope="scope">
        <el-checkbox v-model="scope.row.checkbox2"></el-checkbox>
      </template>
    </el-table-column>
  </el-table>
</template>
<script>
export default {
  props: {
    marketList: {
      type: Array,
      default() {
        return [];
      },
    },
    isSetConfig: {
      type: Boolean,
      default() {
        return true;
      },
    },
  },
  data() {
    return {
      tableData: [
        { checkbox1: false, checkbox2: false },
        { checkbox1: false, checkbox2: false },
        { checkbox1: false, checkbox2: false },
      ],
      selectAll1: false,
      selectAll2: false,
    };
  },
  watch: {
    marketList: {
      handler(newVal) {
        this.tableData = newVal; // 将传递的 marketList 赋值给 tableData
      },
      immediate: true, // 立即执行一次，确保初始赋值
    },
    tableData: {
      handler() {
        this.updateSelectAllStatus();
      },
      deep: true,
    },
    selectAll2: {
      handler(value) {
        for (let i = 0; i < this.tableData.length; i++) {
          this.tableData[i].checkbox2 = value;
        }
      },
      immediate: true,
    },
  },
  methods: {
    handleSelectionChange(val) {
      this.$emit("selection-change", val);
    },
    handleSelectAll(prop) {
      const checked = prop === "checkbox1" ? this.selectAll1 : this.selectAll2;
      for (let i = 0; i < this.tableData.length; i++) {
        this.tableData[i][prop] = checked;
      }
      if (prop === "checkbox2") {
        this.selectAll2 = checked;
      }
    },
    updateSelectAllStatus() {
      this.selectAll1 = this.tableData.every((item) => item.checkbox1);
      this.selectAll2 = this.tableData.every((item) => item.checkbox2);
    },
    getCheckData() {
      return this.tableData;
    },
  },
};
</script>
